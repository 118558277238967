import chroma from "chroma-js";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCountryLegend } from "../store/statistics/statistics.selectors";

const colors = ["#cb1225", "#fdd017", "#029360"];

export const useLegend = (len = 49) => {
  const legend = useSelector(selectCountryLegend);

  // Формируем массив цветов
  const gradient = useMemo(() => {
    return chroma.scale(colors).colors(50);
  }, []);

  // Преобразуем данные для легенды
  const colorsLimit = useMemo(() => chroma.limits(legend.graduates, "l", len), [legend, len]);

  const getColor = useCallback(
    (value: number) => {
      const index = colorsLimit.findIndex((item) => item >= value);
      return gradient[index];
    },
    [gradient, colorsLimit]
  );

  return { gradient, colorsLimit, getColor };
};
