import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SpecializationAPI } from "../../services/specializations.services";
import { IState } from "../../types/NetworkState";

export interface ISpecialization {
  id: number;
  name: string;
  code: string;
  level: number;
}

const initialState: IState<ISpecialization> = {
  entities: [],
  loading: "idle",
};

export const fetchSpecialization = createAsyncThunk(
  "specialization/fetch",
  async (modeId?: string) => {
    const entities = await SpecializationAPI.fetch(modeId);
    return {
      entities,
    };
  }
);

const specializationSlice = createSlice({
  name: "specializations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialization.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchSpecialization.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { reducer } = specializationSlice;

export default reducer;
