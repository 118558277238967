import axios from "axios";
import { IVacancy } from "../store/vacancies/vacancies.reducer";
import { IOptions } from "../types/NetworkState";
import { filterKey } from "../util/filterKeys";

const fetch = async (options: IOptions) => {
  try {
    const response = await axios.post<{ count: number; data: IVacancy[] }>(`/vacancies`, {
      region_id: options.params?.region_id,
      [filterKey.education]: options.filters[filterKey.education],
      [filterKey.level]: options.filters[filterKey.level],
      [filterKey.specialization]: options.filters[filterKey.specialization],
      [filterKey.year]: options.filters[filterKey.year],
    });
    return response.data.data;
  } catch (error) {
    throw new Error("Ошибка при загрузки вакансий");
  }
};

export const VacanciesAPI = { fetch };
