import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegionAPI } from "../../services/regions.services";
import { IState } from "../../types/NetworkState";
import { StatisticDataType } from "../statistics/statistics.reducer";

export interface IRegion extends StatisticDataType {
  id: number;
  r_code: string;
  r_name: string;
  parent_code: string | null;
  parent_id: null | number;
}

const initialState: IState<IRegion> = {
  entities: [],
  loading: "idle",
};

export const fetchRegions = createAsyncThunk("regions/fetch", async () => {
  const entities = await RegionAPI.fetch();
  return {
    entities,
  };
});

const regionsSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    setRegions(state, action: PayloadAction<IRegion[]>) {
      return { ...state, entities: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchRegions.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { actions, reducer } = regionsSlice;

export const { setRegions } = actions;

export default reducer;
