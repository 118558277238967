import React, { useCallback, useMemo } from "react";
import { StatisticDataType } from "store/statistics/statistics.reducer";
import {
  RatingItemChart,
  RatingItemChartBar,
  RatingItemName,
  RatingItemWrapper,
} from "./Rating.styled";

import { ChartBar } from "./types";

type RatingItemProps = {
  bars: ChartBar[];
  data: StatisticDataType[];
  bar: StatisticDataType;
  isRelative?: boolean;
  onClick?: (bar: StatisticDataType) => void;
};

const RatingItem: React.FC<RatingItemProps> = ({
  bar,
  bars,
  data,
  isRelative,
  onClick,
}) => {
  const handleClick = () => onClick && onClick(bar);

  const formatNumber = (num: number) =>
    new Intl.NumberFormat("ru-RU").format(num);

  const getValue = useCallback(
    (b: ChartBar, isPretty?: boolean) => {
      const value = bar[b.key] as number;
      return isPretty ? formatNumber(value) : value;
    },
    [bar]
  );

  const maxValue = useMemo(() => {
    let max = 0;
    for (const b of bars) {
      for (const item of data) {
        if (item[b.key] > max) {
          //console.log(b.key, item[b.key]);
          max = item[b.key] as number;
        }
      }
    }
    return max;
  }, [bars, data]);

  //console.log("maxValue :>> ", maxValue);

  const getWidth = useCallback(
    (b: ChartBar) => {
      let max = 100;
      let value = bar[b.key] as number;
      // Считаем шкалы относительно друг друга
      if (isRelative) {
        max = Math.max(...bars.map((item) => getValue(item) as number));
      } else {
        // Считаем шкалы относительно максимального значения
        max = maxValue;
      }
      return Math.ceil((value / max) * 100);
    },
    [bar, bars, getValue, isRelative, maxValue]
  );

  return (
    <RatingItemWrapper onClick={handleClick}>
      <RatingItemName>{bar.res_name}</RatingItemName>
      <RatingItemChart>
        {bars.map((b) => (
          <RatingItemChartBar key={b.key} width={getWidth(b)} color={b.color}>
            {getValue(b, true)}
          </RatingItemChartBar>
        ))}
      </RatingItemChart>
    </RatingItemWrapper>
  );
};

export default React.memo(RatingItem);
