import "./wdyr";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Provider } from "react-redux";

import { store } from "./store";
import App from "./App";

import pg from "../package.json";

import "components/scss/index.scss";

// Указываем дефолтный URL для запросов
axios.defaults.baseURL = process.env.REACT_APP_BACKEND;
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_API_KEY}`;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

console.log(
  `%c БМКарта version: ${pg.version}`,
  "background-color: #ffdd2d; color: #333;"
);
