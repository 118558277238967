import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { selectRegionByCode } from "../store/regions/regions.selector";
import { fetchStatistics } from "../store/statistics/statistics.reducer";
import { FilterParams } from "./filters.hooks";

type RegionHookProps = {
  region_id: number;
  filter: FilterParams;
};

export const useRegionById = ({ region_id, filter }: RegionHookProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!region_id) return;
    dispatch(
      fetchStatistics({
        filters: filter,
        params: { region_id },
      })
    );
  }, [region_id, filter, dispatch]);
};

export const useRegion = () => {
  const match = useMatch("/:region");
  const region = useSelector(selectRegionByCode(match?.params.region));

  return region;
};
