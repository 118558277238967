import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectRating = (state: RootState) => state.rating;

export const selectRatingLevels = createSelector(selectRating, (state) => state.levels);

export const selectRatingForms = createSelector(selectRating, (state) => state.forms);

export const selectRatingSpecialization = createSelector(
  selectRating,
  (state) => state.specializations
);
