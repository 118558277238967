import styled from "styled-components";
import { device } from "util/mediaQueries";

export const MapHeaderContainer = styled.div`
  position: absolute;
  padding: 15px 10px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const MapHeaderButton = styled.div`
  margin-bottom: 5px;
  @media ${device.tablet} {
    margin-right: 5px;
  }
`;
