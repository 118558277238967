import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Selector } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import Button from "antd/lib/button";

import { RootState } from "store";
import { StatisticDataType } from "store/statistics/statistics.reducer";

import {
  RatingContainer,
  RatingList,
  RatingNone,
  RatingTitle,
} from "./Rating.styled";
import RatingItem from "./RatingItem";
import LegendChart from "./LegendChart";
import { ChartBar } from "./types";

type AsyncChartProps = {
  bars: ChartBar[];
  title: string;
  selector: Selector<RootState, StatisticDataType[], any>;
  action: any;
  onClick: (bar: StatisticDataType) => void;
};

const AsyncChart: React.FC<AsyncChartProps> = ({
  title,
  bars,
  selector,
  action,
  onClick,
}) => {
  const dispatch = useDispatch();
  const statistics = useSelector(selector);

  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(action());
  }, [dispatch, action]);

  const rating = useMemo(
    () =>
      statistics
        .filter((item) => bars.map((bar) => item[bar.key]))
        .sort((a, b) => b.result_graduates - a.result_graduates)
        .slice(0, limit),
    [bars, limit, statistics]
  );

  const handleLoadMore = useCallback(() => {
    const pagesCount = Math.floor(statistics.length / 10);
    const currPage = Math.floor(limit / 10);
    if (currPage <= pagesCount) {
      setLimit(currPage * 10 + 10);
    }
  }, [limit, statistics.length]);

  return (
    <RatingContainer>
      <RatingTitle>
        <h3>{title}</h3>
      </RatingTitle>
      <RatingList>
        {rating.length > 0 ? (
          rating.map((item) => (
            <RatingItem
              key={item.res_name}
              bars={bars}
              data={rating}
              bar={item}
              onClick={onClick}
            />
          ))
        ) : (
          <RatingNone>Нет данных</RatingNone>
        )}
      </RatingList>
      {statistics.length >= 10 && (
        <Button type="link" block onClick={handleLoadMore}>
          Показать больше
        </Button>
      )}
      {rating.length > 0 && <LegendChart bars={bars} />}
    </RatingContainer>
  );
};

export default React.memo(AsyncChart);
