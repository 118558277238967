import React from "react";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import EllipsisOutlined from "@ant-design/icons/lib/icons/EllipsisOutlined";
import styled from "styled-components";

import Filters from "components/Filter/Filters";
import ServicesDropdown from "../ServicesDropdown";

import {
  HeaderBanners,
  HeaderLogo,
  HeaderSwitch,
  HeaderTextLogo,
  HeaderTextLogoName,
  HeaderWrapper,
} from "./Header.styled";

import LogoPNG from "assets/logo.png";
import ZkLogo from "assets/zk-logo.png";

import { device } from "../../util/mediaQueries";

const SButton = styled(Button)`
  font-weight: 500;
  margin-right: 5px;
  span:last-child {
    display: none;
    @media ${device.tablet} {
      display: inline-block;
    }
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderLogo>
        <HeaderTextLogo>
          <HeaderTextLogoName title="Сервис аналитики рынка труда в сфере ИТ (вакансии, компетенции)">
            БМКарта<b>{process.env.REACT_APP_NAME}</b>
          </HeaderTextLogoName>
        </HeaderTextLogo>
        <HeaderSwitch>
          <Dropdown overlay={ServicesDropdown} trigger={["click", "hover"]}>
            <SButton type="ghost" shape="round" icon={<EllipsisOutlined />}>
              Сервисы
            </SButton>
          </Dropdown>
        </HeaderSwitch>
      </HeaderLogo>
      <Filters />
      <HeaderBanners>
        <img src={LogoPNG} alt="" height={36} />
      </HeaderBanners>
    </HeaderWrapper>
  );
};

export default React.memo(Header);
