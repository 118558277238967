import React from "react";
import { RatingLegend, RatingLegendItem } from "./Rating.styled";
import { ChartBar } from "./types";

type LegendChartProps = {
  bars: ChartBar[];
};

const LegendChart = ({ bars }: LegendChartProps) => {
  return (
    <RatingLegend>
      {bars.map((item) => (
        <RatingLegendItem key={item.key} color={item.color}>
          {item.name}
        </RatingLegendItem>
      ))}
    </RatingLegend>
  );
};

export default React.memo(LegendChart);
