import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./app/app.reducer";
import formatsReducer from "./formats/formats.reducer";
import levelsReducer from "./levels/levels.reducer";
import ratingReducer from "./rating/rating.reducer";
import regionsReducer from "./regions/regions.reducer";
import specializationsReducer from "./specializations/specializations.reducer";
import statisticsReducer from "./statistics/statistics.reducer";
import vacanciesReducer from "./vacancies/vacancies.reducer";

export const middleware = (getDefaultMiddleware: any) => {
  return getDefaultMiddleware({ immutableCheck: true, thunk: true });
};

export const reducer = {
  app: appReducer,
  regions: regionsReducer,
  specialization: specializationsReducer,
  levels: levelsReducer,
  formats: formatsReducer,
  statistics: statisticsReducer,
  vacancies: vacanciesReducer,
  rating: ratingReducer,
};

export const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
