import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LevelsAPI } from "../../services/levels.services";
import { IState } from "../../types/NetworkState";

export interface ILevel {
  id: number;
  name: string;
}

const initialState: IState<ILevel> = {
  entities: [],
  loading: "idle",
};

export const fetchLevels = createAsyncThunk("levels/fetch", async () => {
  const entities = await LevelsAPI.fetch();
  return {
    entities,
  };
});

const levelsSlice = createSlice({
  name: "levels",
  initialState,
  reducers: {
    setLevels: (_, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLevels.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchLevels.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { reducer } = levelsSlice;

export default reducer;
