import axios from "axios";
import { IFormat } from "../store/formats/formats.reducer";

const fetch = async () => {
  try {
    const response = await axios.get<IFormat[]>("/education_type");
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке");
  }
};

export const FormatAPI = { fetch };
