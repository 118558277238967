import axios from "axios";
import { ILevel } from "store/levels/levels.reducer";

const fetch = async () => {
  try {
    const response = await axios.get<ILevel[]>("/education_level");
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке");
  }
};

export const LevelsAPI = { fetch };
