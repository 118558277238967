import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatisticAPI } from "../../services/statistics.service";
import { IOptions } from "../../types/NetworkState";
import { StatisticDataType } from "../statistics/statistics.reducer";

export interface IRating {
  levels: StatisticDataType[];
  forms: StatisticDataType[];
  specializations: StatisticDataType[];
}

const initialState: IRating = {
  levels: [],
  forms: [],
  specializations: [],
};

export const fetchRatingByLevels = createAsyncThunk(
  "rating/fetchRatingByLevels",
  async (options: IOptions) => {
    try {
      const rating = await StatisticAPI.fetch(options.filters, {
        ...options.params,
        slice: "level",
      });
      return rating;
    } catch (error: any) {
      return [];
    }
  }
);

export const fetchRatingByForms = createAsyncThunk(
  "rating/fetchRatingByForms",
  async (options: IOptions) => {
    try {
      const rating = await StatisticAPI.fetch(options.filters, {
        ...options.params,
        slice: "education",
      });
      return rating;
    } catch (error: any) {
      return [];
    }
  }
);

export const fetchRatingBySpecialization = createAsyncThunk(
  "rating/fetchRatingBySpecialization",
  async (options: IOptions) => {
    try {
      const rating = await StatisticAPI.fetch(options.filters, {
        ...options.params,
        slice: "specialization",
      });
      return rating;
    } catch (error: any) {
      return [];
    }
  }
);

const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatingByLevels.fulfilled, (state, action) => {
        return { ...state, levels: action.payload };
      })
      .addCase(fetchRatingByForms.fulfilled, (state, action) => {
        return { ...state, forms: action.payload };
      })
      .addCase(fetchRatingBySpecialization.fulfilled, (state, action) => {
        return { ...state, specializations: action.payload };
      });
  },
});

const { reducer } = ratingSlice;

export default reducer;
