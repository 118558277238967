import React from "react";
import { useLegend } from "hooks/legend.hooks";
import {
  MapGradientBar,
  MapGradientBarPoint,
  MapGradientContainer,
  MapGradientLabels,
} from "../Map/Map.styled";

function MapLegendGradient() {
  const { colorsLimit, gradient } = useLegend(3);

  const backgroundImage = `linear-gradient(to right, ${gradient.join(",")})`;

  return (
    <MapGradientContainer>
      <MapGradientBar background={backgroundImage}>
        {colorsLimit.map((item) => (
          <MapGradientBarPoint key={item} />
        ))}
      </MapGradientBar>
      <MapGradientLabels>
        {colorsLimit.map((item) => (
          <span key={item}>{Math.round(item)}</span>
        ))}
      </MapGradientLabels>
    </MapGradientContainer>
  );
}

export default React.memo(MapLegendGradient);
