import Menu from "antd/lib/menu";

const isTest =
  window.location.hostname.startsWith("test.") ||
  process.env.NODE_ENV === "development";

function ServicesDropdown() {
  return (
    <Menu style={{ maxWidth: 200 }}>
      <Menu.Item key="vacancy">
        <a
          rel="noopener noreferrer"
          href={
            isTest
              ? "https://test.resume.yanao.2035.university/"
              : "https://resume.yanao.2035.university/"
          }
        >
          Резюме
        </a>
      </Menu.Item>
      <Menu.Item key="junior">
        <a
          rel="noopener noreferrer"
          href={
            isTest
              ? "https://test.staff.yanao.2035.university/"
              : "https://staff.yanao.2035.university/"
          }
        >
          Кадры
        </a>
      </Menu.Item>
      <Menu.Item key="trends">
        <a
          rel="noopener noreferrer"
          href={
            isTest
              ? "https://test.trends.yanao.2035.university/"
              : "https://trends.yanao.2035.university/"
          }
        >
          Тренды
        </a>
      </Menu.Item>
    </Menu>
  );
}

export default ServicesDropdown;
