import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectLevels = (state: RootState) => state.levels;

export const selectLevelsEntities = createSelector(selectLevels, (state) => state.entities);

export const selectLevelsOptions = createSelector(selectLevels, (state) =>
  state.entities
    .map((item) => ({ value: item.id, label: item.name }))
    .sort((a, b) => a.label.localeCompare(b.label, "ru"))
);

export const selectFieldById = (id?: number) =>
  createSelector(selectLevels, (state) => state.entities.find((item) => item.id === id));
