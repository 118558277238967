import Button from "antd/lib/button";
import { CloseOutlined } from "@ant-design/icons/lib/icons";
import { SidebarTitle as Title } from "./Sidebar.styled";
import { IRegion } from "store/regions/regions.reducer";
type SidebarTitleProps = {
  region?: IRegion;
  onBackToCountry: () => void;
};
const SidebarTitle: React.FC<SidebarTitleProps> = ({
  region,
  onBackToCountry,
}) => {
  if (region) {
    return (
      <Title>
        {region.r_name}{" "}
        <Button icon={<CloseOutlined />} onClick={onBackToCountry} />
      </Title>
    );
  }
  return null;
};

export default SidebarTitle;
