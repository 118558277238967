import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectFormats = (state: RootState) => state.formats;

export const selectFormatsEntities = createSelector(selectFormats, (state) => state.entities);

export const selectFormatsOptions = createSelector(selectFormats, (state) =>
  state.entities
    .map((item) => ({ value: item.id, label: item.name }))
    .sort((a, b) => a.label.localeCompare(b.label, "ru"))
);

export const selectFormatsById = (id?: number) =>
  createSelector(selectFormats, (state) => state.entities.find((item) => item.id === id));
