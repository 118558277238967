import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";
import { StatisticDataType } from "./statistics.reducer";

export const selectStatistics = (state: RootState) => state.statistics;

export const selectAllStatistics = createSelector(
  selectStatistics,
  (state) => state
);

export const selectRegionsMap = createSelector(selectStatistics, (state) =>
  state.reduce<Record<string, StatisticDataType>>(
    (acc, item: StatisticDataType) => {
      if (!item.res_code) return acc;
      acc[item.res_code] = item;
      return acc;
    },
    {}
  )
);

export const selectCountryLegend = createSelector(selectStatistics, (state) =>
  state.reduce<Record<"graduates" | "vacancies", number[]>>(
    (acc, item) => {
      const graduates = Math.max(1, item.result_graduates);
      // ***
      // const vacancies = Math.max(1, item.result_vacancies);
      acc.graduates.push(graduates);
      // ***
      // acc.vacancies.push(vacancies);
      return acc;
    },
    { graduates: [], vacancies: [] }
  )
);

export const selectCitiesMap = createSelector(selectStatistics, (state) =>
  state.reduce<Record<string, StatisticDataType>>(
    (acc, item: StatisticDataType) => {
      acc[item.res_name] = item;
      return acc;
    },
    {}
  )
);

export const selectRegion = (region: string) =>
  createSelector(selectStatistics, (state) => {
    return state.find((item) => {
      return item.res_code === region;
    });
  });
