import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useRegion } from "hooks/region.hooks";
import { useFilters } from "hooks/filters.hooks";
import { selectAllStatistics } from "store/statistics/statistics.selectors";
import { StatisticDataType } from "store/statistics/statistics.reducer";
import {
  selectRatingForms,
  selectRatingLevels,
} from "store/rating/rating.selectors";

import {
  fetchRatingByForms,
  fetchRatingByLevels,
} from "store/rating/rating.reducer";

import { ChartBar } from "../Charts/types";
import SidebarTitle from "./SidebarTitle";
import RegularChart from "../Charts/RegularChart";
import AsyncChart from "../Charts/AsyncChart";

import { filterKey } from "util/filterKeys";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const statistics = useSelector(selectAllStatistics);
  const [filter, setFilter] = useFilters();
  const [search] = useSearchParams();
  const region = useRegion();

  const handleBackToCountry = useCallback(() => {
    const queryString = search.toString();
    // Перемещаемся на главную, с сохранением фильтров
    if (queryString) {
      navigate(`/?${queryString}`);
    } else {
      navigate(`/`);
    }
  }, [navigate, search]);

  const handleSelect = useCallback(
    (bar: StatisticDataType) => {
      if (!bar.res_code) return;
      const queryString = search.toString();
      if (queryString) {
        navigate(`/${bar.res_code}?${queryString}`);
      } else {
        navigate(`/${bar.res_code}`);
      }
    },
    [navigate, search]
  );

  const handleSelectBySlice = useCallback(
    (bar: StatisticDataType, slice: string) => {
      setFilter({ [filterKey[slice]]: bar.res_code });
    },
    [setFilter]
  );

  const bars: ChartBar[] = [
    {
      name: "Количество выпускников",
      key: "result_graduates",
      color: "#1585da",
    },
    {
      name: "Количество поступивших",
      key: "result_accepted",
      color: "#26e7a6",
    },
  ];

  const filteredStatistics = statistics.filter((item) =>
    region ? item.res_code === region.r_code : true
  );

  const fetchLevels = useCallback(
    () =>
      fetchRatingByLevels({
        filters: filter,
        params: { region_id: region?.id },
      }),
    [region?.id, filter]
  );

  const fetchFormats = useCallback(
    () =>
      fetchRatingByForms({
        filters: filter,
        params: { region_id: region?.id },
      }),
    [region?.id, filter]
  );

  /* const fetchSpecializations = useCallback(
    () =>
      fetchRatingBySpecialization({
        filters: filter,
        params: { region_id: region?.id },
      }),
    [region?.id, filter]
  ); */

  return (
    <>
      <SidebarTitle region={region} onBackToCountry={handleBackToCountry} />
      <RegularChart
        data={filteredStatistics}
        onClick={handleSelect}
        bars={bars}
        title="Выпуск/Набор"
      />
      {/* <AsyncChart
        selector={selectRatingSpecialization}
        action={fetchSpecializations}
        bars={bars}
        onClick={(bar) => handleSelectBySlice(bar, "specialization")}
        title={`Срез по уровню специализации`}
      /> */}

      <AsyncChart
        selector={selectRatingLevels}
        action={fetchLevels}
        bars={bars}
        onClick={(bar) => handleSelectBySlice(bar, "level")}
        title={`Срез по уровню обучения`}
      />

      <AsyncChart
        selector={selectRatingForms}
        action={fetchFormats}
        bars={bars}
        onClick={(bar) => handleSelectBySlice(bar, "education")}
        title={`Срез по формату образования`}
      />
    </>
  );
};

export default React.memo(Sidebar);
