import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FormatAPI } from "../../services/formats.services";
import { IState } from "../../types/NetworkState";

export interface IFormat {
  id: number;
  name: string;
}

const initialState: IState<IFormat> = {
  entities: [],
  loading: "idle",
};

export const fetchFormats = createAsyncThunk("formats/fetch", async () => {
  const entities = await FormatAPI.fetch();
  return {
    entities,
  };
});

const formatsSlice = createSlice({
  name: "formats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormats.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchFormats.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { reducer } = formatsSlice;

export default reducer;
