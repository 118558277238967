import Select from "antd/lib/select";
import styled from "styled-components";
import { device } from "util/mediaQueries";

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  order: 1;
  flex-wrap: wrap;
  flex-basis: 100%;
  @media ${device.tablet} {
    flex-wrap: nowrap;
    gap: 10px;
  }
  @media ${device.laptopL} {
    align-items: center;
    order: 0;
  }
`;

export const FilterSelect = styled(Select)`
  display: flex;
  flex: 1;
`;
