import React from "react";
import SelectMapRegion from "./SelectMapRegion";
import { MapHeaderButton, MapHeaderContainer } from "./MapHeader.styled";

const MapHeader = () => {
  return (
    <MapHeaderContainer>
      <MapHeaderButton>
        <SelectMapRegion />
      </MapHeaderButton>
    </MapHeaderContainer>
  );
};

export default React.memo(MapHeader);
