import axios from "axios";
import { FilterParams } from "hooks/filters.hooks";
import { StatisticDataType } from "store/statistics/statistics.reducer";
import { RequestOptions } from "types/NetworkState";
import { filterKey } from "util/filterKeys";

const fetch = async (filters: FilterParams, params?: RequestOptions) => {
  try {
    const response = await axios.get<StatisticDataType[]>("/students", {
      params: {
        ...params,
        [filterKey.education]: filters[filterKey.education],
        [filterKey.level]: filters[filterKey.level],
        [filterKey.specialization]: filters[filterKey.specialization],
        [filterKey.year]: filters[filterKey.year],
      },
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

export const StatisticAPI = { fetch };
