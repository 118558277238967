import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VacanciesAPI } from "../../services/vacancies.services";
import { IOptions, IState } from "../../types/NetworkState";

export interface IVacancy {
  avg_salary: number;
  employer: string;
  link: string;
  profession: string;
  published_at: string;
  rec_id: number;
  remote: boolean;
  salary_from: number;
  salary_to: number;
  src_id: number;
  town_id: number;
}

const initialState: IState<IVacancy> = {
  entities: [],
  loading: "idle",
};

export const fetchVacancies = createAsyncThunk("vacancies/fetch", async (options: IOptions) => {
  const entities = await VacanciesAPI.fetch(options);
  return {
    entities,
  };
});

const vacanciesSlice = createSlice({
  name: "vacancies",
  initialState,
  reducers: {
    setVacancies(state, action: PayloadAction<IVacancy[]>) {
      return { ...state, entities: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancies.fulfilled, (state, action) => {
        return { ...state, ...action.payload, loading: "done" };
      })
      .addCase(fetchVacancies.pending, (state) => {
        return { ...state, loading: "pending" };
      });
  },
});

const { actions, reducer } = vacanciesSlice;

export const { setVacancies } = actions;

export default reducer;
